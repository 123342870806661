<template>
  <div>
    <Toast position="top-right" />
    <modal name="modal-form-faq" width="550px" height="auto">
      <div style="padding: 20px; height: auto">
        <h2 class="text-center">
          {{ faq_payload.conta_faq_id ? "Editar" : "Novo" }} FAQ
        </h2>
        <b-form-group
          label="Pergunta"
          label-for="pergunta-input"
          invalid-feedback="A pergunta é necessária"
        >
          <b-form-input
            id="pergunta-input"
            v-model="faq_payload.pergunta"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Resposta"
          label-for="resposta-input"
          invalid-feedback="A resposta é necessária"
        >
          <b-form-input
            id="resposta-input"
            v-model="faq_payload.resposta"
            @keydown.enter.prevent="handleSubmitFAQ"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <ToggleButton
            onLabel="Pergunta Ativa"
            offLabel="Pergunta Inativa"
            v-model="faq_payload.ativa"
            class="w-100 text-uppercase"
          />
        </b-form-group>
        <br />
        <b-button
          class="btn-agente-sistema w-100"
          variant="success"
          @click="handleSubmitFAQ"
          @keydown.enter.prevent="handleSubmitFAQ"
        >
          {{ faq_payload.conta_faq_id ? "Editar" : "Salvar" }}</b-button
        >
      </div>
    </modal>
    <div class="card">
      <div class="card-body p-4 py-4">
        <p>
          As perguntas frequentes são utilizadas para educar a <b>Letícia</b>,
          que ficará responsável por tirar todas as dúvidas dos seus clientes
          sobre a sua clínica
        </p>
        <b-form-input
          v-model="busca_faq"
          @input="buscarFAQ"
          placeholder="Buscar pergunta"
        ></b-form-input>
        <br />
        <ul class="list-group">
          <li
            class="faq-item-container"
            :class="{ active: faq.cof_ativa }"
            v-for="(faq, idx) in faqs_filtrados"
            :key="faq.conta_faq_id"
          >
            <div class="flexer w-100">
              <div class="faq-idx-container">
                <p class="pergunta-numero">{{ calculateFAQIdx(idx) }}</p>
              </div>
              <div>
                <p class="pergunta">{{ faq.cof_pergunta }}</p>
                <p class="resposta">{{ faq.cof_resposta }}</p>
              </div>
            </div>
            <div class="acoes-container">
              <div class="editar" @click="abrirModalFormFAQ(faq)">
                <i class="fa-solid fa-pen" />
              </div>
              <div class="remover" @click="deletarFAQ(faq)">
                <i class="fa-solid fa-times" />
              </div>
            </div>
          </li>
        </ul>
        <button class="novo-menu-btn" @click="abrirModalFormFAQ">
          + Nova Pergunta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";
import Swal from "sweetalert2";

export default {
  components: {
    Toast: () => import("primevue/toast"),
    ToggleButton: () => import("primevue/togglebutton")
  },
  data() {
    return {
      faqs_conta: [],
      faqs_filtrados: [],
      busca_faq: "",
      faq_payload: {
        pergunta: "",
        resposta: "",
        ativa: true
      }
    };
  },
  methods: {
    buscarFAQ() {
      this.faqs_filtrados = this.faqs_conta.filter((faq) => {
        const tem_na_pergunta = faq.cof_pergunta
          .toLowerCase()
          .includes(this.busca_faq.toLowerCase());

        const tem_na_resposta = faq.cof_resposta
          .toLowerCase()
          .includes(this.busca_faq.toLowerCase());

        return tem_na_pergunta || tem_na_resposta;
      });
    },
    async handleSubmitFAQ() {
      if (this.faq_payload.conta_faq_id) {
        return await this.editarFAQ();
      }
      const payload = {
        faq: {
          ...this.faq_payload
        }
      };
      await ApiService.post(API_LINKS.faq, payload);
      Swal.fire({
        title: "Pergunta cadastrada com sucesso!",
        icon: "success",
        showConfirmButton: false,
        timer: 500
      });
      this.getFAQs();
      this.$modal.hide("modal-form-faq");
    },
    async editarFAQ() {
      const payload = {
        faq: {
          ...this.faq_payload
        }
      };
      await ApiService.put(API_LINKS.faq, payload);
      this.getFAQs();
      Swal.fire({
        title: "Pergunta editada com sucesso!",
        icon: "success",
        showConfirmButton: false,
        timer: 500
      });
      this.$modal.hide("modal-form-faq");
    },
    async getFAQs() {
      const faqs = await ApiService.get(API_LINKS.faq);
      const active_faqs = faqs.data.faqs.filter((faq) => faq.cof_ativa);
      const inactive_faqs = faqs.data.faqs.filter((faq) => !faq.cof_ativa);
      active_faqs.sort((a, b) => {
        return a.conta_faq_id - b.conta_faq_id;
      });
      inactive_faqs.sort((a, b) => {
        return new Date(b.cof_data_cadastro) - new Date(a.cof_data_cadastro);
      });
      this.faqs_conta = [...active_faqs, ...inactive_faqs];
      this.buscarFAQ();
    },
    async deletarFAQ(faq) {
      Swal.fire({
        title: "Deseja realmente deletar esta pergunta?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          const payload = {
            conta_faq_id: faq.conta_faq_id
          };
          await ApiService.delete(API_LINKS.faq, payload);
          await this.getFAQs();
          Swal.fire({
            title: "Pergunta deletada com sucesso!",
            icon: "success",
            showConfirmButton: false,
            timer: 500
          });
        }
      });
    },
    calculateFAQIdx(idx) {
      return idx + 1 < 10 ? `0${idx + 1}` : idx + 1;
    },
    async abrirModalFormFAQ(faq) {
      if (!faq) {
        this.$modal.show("modal-form-faq");
        this.faq_payload = {
          pergunta: "",
          resposta: "",
          ativa: true
        };
      } else {
        this.faq_payload = {
          pergunta: faq.cof_pergunta,
          resposta: faq.cof_resposta,
          ativa: faq.cof_ativa,
          conta_faq_id: faq.conta_faq_id
        };
        this.$modal.show("modal-form-faq");
      }
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "FAQ",
        route: "/painel/chatbot/faq"
      }
    ]);
    const init = async () => {
      this.getFAQs();
    };
    init();
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.p-togglebutton.p-button {
  background: #e4e6ef;
  border: 1px solid #ced4da;
  color: #495057 !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  text-transform: uppercase;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057 !important;
}

.p-togglebutton.p-button.p-highlight {
  background: #e8f3d6;
  border-color: #e8f3d6;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.faq-item-container {
  display: flex;
  background: #eeeeee;
  opacity: 0.5;
  &.active {
    background: #e8f3d6;
    opacity: 1;
  }
  border-radius: 8px !important;
  align-items: center;
  justify-content: space-betweens;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  p {
    margin: 0;
  }
  .flexer {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .acoes-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    div {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
    }
    .editar {
      background: #f8f9fa;
      i {
        color: #686868 !important;
      }
    }
    .remover {
      background: #ff5f65;
      i {
        color: #fff !important;
      }
    }
  }
  .faq-idx-container {
    font-size: 32px;
    margin-right: 12px;
    .pergunta-numero {
      color: #472282;
      margin: 0;
    }
  }
  .pergunta {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  .resposta {
    font-size: 1rem;
    color: #000;
    margin: 0;
  }
}

.novo-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px dashed #000;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  height: 59px;
  &:hover {
    background: #eee;
    color: #000;
  }
}
</style>
